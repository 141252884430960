/* Les CDN de Google Fonts ont été ajouté dans le fichier index.html */
.font-Montserrat{
    font-family: 'Montserrat';
    color: rgba(0, 0, 0,0.7);
    font-weight: 600;
}
/* Font wight */
.fw-100{
    font-weight: 100;
}
.fw-600{
    font-weight: 600;
}
/* Font size */
.fs-90{
    font-size: 90%;
}
.fs-80{
    font-size: 80%;
}
.fs-70{
    font-size: 70%;
}
.fs-60{
    font-size: 60%;
}