.card-boutique{
    border-right-color: #F8C47E;
    border-right-width: 10px;
    background-color: #008FA0;
}
.card-vente{
    border-right-color: #FF9A7F;
    border-right-width: 10px;
    background-color: #008FA0;
}
.card-depense{
    border-right-color: #9BF0FB;
    border-right-width: 10px;
    background-color: #008FA0;
}
.card-benefice{
    border-right-color: #43DA8E;
    border-right-width: 10px;
    background-color: #008FA0;
}
.card-pret{
    border-right-color: #F8C47E;
    border-right-width: 10px;
    background-color: #008FA0;
}
.card-dette{
    border-right-color: #FF9A7F;
    border-right-width: 10px;
    background-color: #008FA0;
}
.card-inventaire{
    border-right-color: #9BF0FB;
    border-right-width: 10px;
    background-color: #008FA0;
}
.card-contact{
    border-right-color: #43DA8E;
    border-right-width: 10px;
    background-color: #008FA0;
}
.text-xs{
    color:white;
    padding-left: 0%;
}
.para{
    font-weight: bold;
    color: white;
}
.pie-style{
    height: 260px;
}