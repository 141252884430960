*{
    font-family: 'Montserrat', sans-serif;
}

:root{
    --secondary-color: #00BEC9;
}

ul{
    list-style: none;
    margin: 0;
}

a{
    text-decoration: none !important;
}

h1{
    font-weight: 700 !important;
}
label .text-danger{
    font-size: 0.8rem !important;
}
select,option{
    cursor: pointer !important;
}
option{
    color: black !important;
}
.hover-1:hover{
    opacity: 0.7;
}

/* ******************** custom style ********************* */
.logout-btn-container *{
    font-weight: bold !important;
}
.logout-btn-container:hover{
    background-color: rgba(231, 39, 39, 0.8);
    cursor: pointer;
    color: white;
}
.logout-btn-container:hover *{
    color: white;
}
.text-error{
    margin-top: 0.2rem;
    color: #F9F871 !important;
    font-weight: 300;
  }

  .text-error--danger{
    margin-top: 0.2rem;
    color: rgb(216, 61, 61) !important;
    font-weight: 300;
    font-size: 0.8rem;
  }

  td{
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* .facture--products th, .facture--products td{
    padding: 0 !important;
  } */

  label{
    width: 100%;
  }

  input, select{
    border: 1px solid rgb(104, 100, 100);
    padding: 0.5rem;
    width: 100%;
    /* border-radius: 8px; */
  }
  input.invalid{
    border: 1px solid red;
  }

  textarea:focus, input:focus{
    outline: none !important;
}

/* *********************Start Template************************* */
/* .interface-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.interface-container > .navbar-container{
    flex: 1;
}
.interface-container > .body-container{
    flex: 12;
    display: flex;
}
.interface-container > .body-container > .sidebar-container{
    flex: 3;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
}
.interface-container > .body-container > .children-container{
    flex: 13;
} */

.interface-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.interface-container>.navbar-container {
    flex: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 4;
}

.interface-container>.body-container {
    /* flex: 12; */
    display: flex;
}

.interface-container>.body-container>.sidebar-container {
    height: 100vh;
    position: fixed;
    top: 50px;
    left: 0%;
    background-color: #fff;
    transition: width 0.3s;
    z-index: 2;
}


.interface-container>.body-container>.sidebar-container>.menu-controller {
    position: absolute;
    top: 5px;
    right: -10px;
    width: 25px;
    height: 25px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #008FA0;
    cursor: pointer;
    z-index: 3;
}

.interface-container>.body-container>.sidebar-container>.menu-controller:hover {
    background-color: #008FA0;
}

.interface-container>.body-container>.sidebar-container>.menu-controller:hover>.menu-controller-icon {
    color: #fff;
}

.interface-container>.body-container>.children-container {
    width: 100%;
    height: 100vh;
    background-color: rgba(241, 241, 241, 0.324);
    transition: padding 0.3s;
    overflow-x: hidden;
    z-index: 1;
}

/* *********************End Template************************* */


/* *********************Start NavBar************************* */
.navbar-container {
    background-color: #434445;
}

.navbar-container .navbar-separator {
    display: inline;
    height: 30px;
    background-color: red;
}

/* *********************End NavBar************************* */


/* *********************Start SideBar************************* */
.sidebar {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* overflow-y: scroll !important; */
}
.sidebar .item .icon {
    font-size: 130%;
}

.sidebar .item {
    height: 45px;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.sidebar .item:hover {
    background-color: rgba(0, 143, 160, 0.2);
}

.sidebar .item.actif {
    color: #008FA0;
    border-right: 5px solid #008FA0;
}

.sidebar>.sidebar_items_bottom {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

/* *********************End SideBar************************* */


/* *********************Start Acceuil************************* */
.card-boutique {
    border-right-color: #F8C47E;
    border-right-width: 10px;
    background-color: #008FA0;
}

.card-vente {
    border-right-color: #FF9A7F;
    border-right-width: 10px;
    background-color: #008FA0;
}

.card-depense {
    border-right-color: #9BF0FB;
    border-right-width: 10px;
    background-color: #008FA0;
}

.card-benefice {
    border-right-color: #43DA8E;
    border-right-width: 10px;
    background-color: #008FA0;
}

.card-pret {
    border-right-color: #F8C47E;
    border-right-width: 10px;
    background-color: #008FA0;
}

.card-dette {
    border-right-color: #FF9A7F;
    border-right-width: 10px;
    background-color: #008FA0;
}

.card-inventaire {
    border-right-color: #9BF0FB;
    border-right-width: 10px;
    background-color: #008FA0;
}

.card-contact {
    border-right-color: #43DA8E;
    border-right-width: 10px;
    background-color: #008FA0;
}

.text-xs {
    color: white;
    padding-left: 0%;
}

.para {
    font-weight: bold;
    color: white;
}

.pie-style {
    height: 260px;
}

/* *********************End Acceuil************************* */



/* *********************End Agence************************* */

/* ***************** facture ******************** */
.articles-grid{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(120px,1fr));
    gap: 15px;
    max-block-size: 61vh;
    overflow-y: auto;
    padding-bottom: 2px;
}
.add-to-articles{
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, .8);
    color: white;
    display: none;
    transition: all 500ms ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.img-article:hover .add-to-articles{
    display: block;
}
/* .truncate-title {
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
} */
.text-gris{
    color: #8c98a4!important;
    font-size: 12px;
}
.product table{
    min-width: 100px !important;
}
.product table th, .product table td{
    text-align: center;
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
}
.product table th{
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}
.product table td, .product table th{
    font-size: 12px !important;
    vertical-align: middle;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
    /* display: flex; */
    /* align-items: center; */
}
.action-btn:hover{
    background-color: #98ebef !important;
}
.custom-button-secondary{
    color: white;
    border-radius: 5px;
    border: none;
    /* border: 2px solid white; */
    background-color: #00BEC9;
    box-shadow: #aaa 0px 0px 7px;
}

/* *********************Start Auth************************* */
/* Login */
.login-top-bar {
    height: 50px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #00BEC9;
}

.barre {
    width: 1px;
    height: 80%;
    border: 1px solid #00BEC9;
}

.login-page {
    min-height: 100vh;
    background: linear-gradient(180deg, #008FA0 0%, #00BEC9 100%);

}

.login-hr {
    width: 24%;
    margin: auto;
    border: 1px solid rgba(255, 255, 255, 0.5);
    opacity: 1;
    border-radius: 50px;
}

.image-fewnu-login {
    width: 250px;
}

.login-input {
    border-radius: 7px;
    border: none;
    height: 42.09px;
    color: white;
    background-color: rgba(255, 255, 255, 0.5);
}

.login-input:focus {
    margin-top: 20px;
    width: 100%;
    border-radius: 7px;
    border: none;
    color: white;
    background-color: rgba(255, 255, 255, 0.5);
    /* border: #00BEC9 2px; */
}

.login-input::placeholder {
    color: rgb(36, 33, 33);
}

.login-btn {
    width: 100%;
    color: #181919;
    font-weight: bold;
    border: none;

    height: 42.09px;
    background: #FFFFFF;
    box-shadow: 0px 3.74131px 3.74131px rgba(0, 0, 0, 0.3);
    border-radius: 9.35329px;
}

/* Register */
.barre {
    width: 1px;
    height: 80%;
    border: 1px solid #00BEC9;
}

.register-page {
    min-height: 100vh;
    background: linear-gradient(180deg, #008FA0 0%, #00BEC9 100%);

}

.register-input {
    border-radius: 7px;
    border: none;
    color: white;
    background-color: rgba(255, 255, 255, 0.5);
}

.register-input:focus {
    width: 100%;
    border-radius: 7px;
    border: none;
    color: white;
    background-color: rgba(255, 255, 255, 0.5);
    /* border: #00BEC9 2px; */
}

.register ::placeholder {
    color: rgb(109, 99, 99) !important;
}

.register-btn {
    width: 100%;
    height: 40px;
    color: #00BEC9;
    font-weight: bold;
    border: none;
    border-radius: 7px;
    transition: 0.3s all !important;
    text-transform: uppercase;
}
.register-btn:active {
    transform: translateY(13px) !important;
    border: 1px solid transparent !important;
    opacity: 0.2 !important;
  }

/* Mot de passe oublier */
.mdp-top-bar {
    height: 50px;
    background: #008FA0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #ffffff;
}

.barre-mdp {
    width: 1px;
    height: 80%;
    border: 1px solid #ffffff;
}

.cartes {
    background: #008FA0;
    color: #ffffff;
    text-align: left;
}

.send {
    background: #ffffff;
    color: #008FA0;
    width: 35%;
    font-size: 15px;
    border-radius: 10px;
}

.cartes h5 {
    font-size: 20px;
    line-height: 24.38px;
    font-weight: 600;

}

.cartes p {
    font-size: 14px;
    line-height: 17.07px;
    font-weight: 500;
}

/* input[type=text] {
    background: #5ABEC8;
} */

input[type=text]::placeholder {
    color: #ffffff;
}

/* Reinitialiser Mot de passe */
.mdp-top-bar {
    height: 50px;
    background: #008FA0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #ffffff;
}

.barre-mdp {
    width: 1px;
    height: 80%;
    border: 1px solid #ffffff;
}

.card-middle {
    background: #008FA0;
    color: #ffffff;
    text-align: left;
}

.send {
    background: #ffffff;
    color: #008FA0;
    width: 35%;
    font-size: 15px;
    border-radius: 10px;
}

.card-middle {
    width: 400px;
    height: auto;
    margin: 0 auto;
    /* padding: 10px; */
    position: relative;
}

.card-middle h5 {
    font-size: 20px;
    line-height: 24.38px;
    font-weight: 600;

}

.card-middle p {
    font-size: 14px;
    line-height: 17.07px;
    font-weight: 500;
}

/* input[type=text] {
    background: #5ABEC8;
}
input[type=text]::placeholder {
    color: #ffffff;
} */

/* Recuperation password */
.mdp-top-bar {
    height: 50px;
    background: #008FA0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #ffffff;
}

.barre-mdp {
    width: 1px;
    height: 80%;
    border: 1px solid #ffffff;
}

.card-middle {
    background: #008FA0;
    color: #ffffff;
    text-align: left;
}

.send {
    background: #ffffff;
    color: #008FA0;
    width: 35%;
    font-size: 15px;
    border-radius: 10px;
}

.card-middle {
    width: 400px;
    height: auto;
    margin: 0 auto;
    /* padding: 10px; */
    position: relative;
}

.card-middle h5 {
    font-size: 20px;
    line-height: 24.38px;
    font-weight: 600;

}

/* input[type=text] {
    background: #5ABEC8;
} */
/* input[type=text]::placeholder {
    color: #ffffff;
} */
/* *********************End Auth************************* */


/* *********************Start Client************************* */
.add {
    background-color: #00BEC9;
    ;
    color: #ffffff;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);

}

.loading-btn{
    /* background-color: #00BEC9; */
    color: #ffffff;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
    border: none;
}

.close,
.cursor {
    cursor: pointer;
}

/* *********************End Client************************* */


/* *********************Start Gerant************************* */
.gerantsContainer {
    text-align: left !important;
}

.bouton {
    background-color: #00BEC9;
    ;
    color: #ffffff;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    width: 100px;
    height: 40px;
}

.bouton:hover {
    background-color: #00BEC9;
    ;
}

/* *********************End Gerant************************* */


/* *********************Start Modal************************* */
.modal-container {
    width: 100vw;
    height: 100vh;
    background-color: #0000004d;
    position: fixed;
    left: 0%;
    top: 0%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-container>.modal-children {
    min-width: 300px;
    max-width: 650px;
    height: auto;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    position: relative;
    margin: 0 10px;
    margin-top: -20%;
}

.modal-container>.modal-children>.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.modal-container>.modal-children>.modal-header>.modal-close-icon {
    /* background-color: #0000004d; */
    padding: 0 10px;
    margin-left: 10px;
    border-radius: 5px;
    color: #00BEC9;
    cursor: pointer;
}

/* *********************End Modal************************* */


/* *********************Start ************************* */

/* *********************End ************************* */

/* *********************** table ********************** */

/* ************** scrollbar ************** */
/* width */
::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(80, 74, 74);
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(69, 67, 67);
    border-radius: 10px;
  }

.react-bootstrap-table{
    overflow-x: auto !important;
}
table{
    table-layout: fixed !important;
    min-width: 1200px;
}
table>thead {
    vertical-align: bottom;
    background-color: #333639;
    color: white;
}
table  thead th{
    font-weight: 500;
    padding: 1rem 1rem !important;
}
tbody > tr{
    border: 1px solid gray;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 1.5rem 12rem !important;
    background-color: white;
}
td{
    background-color: red;
    padding: 0.95rem !important;
    font-weight: 400;
    border: none !important;
    word-wrap:break-word;
    /* white-space: nowrap; */
}
td:nth-child(2n){
    border-left: 1px solid rgb(183, 180, 180) !important;
    border-right: 1px solid rgb(183, 180, 180) !important;
}
td:last-child{
    border-right: none !important;
}

/* ***************** filter btn ******************* */
.filter-btn{
    max-width: 500px;
    padding-left: 50px;
}
.search-icon{
    position: absolute;
    left: 28px;
    top: 50%;
    transform: translate(-50%, -50%);
}
.btn-add{
    font-weight: 500 !important;
    background-color: #00BEC9;
    color: white;
    border: none;
    border-radius: 0px !important;
    box-shadow: 0px 1px 1px rgba(10, 12, 15, 0.25);
}

/* ******************** Pagination ****************** */
.react-bootstrap-table-pagination{
    background-color: #EFEDED;
    padding: 0.3rem 0.5rem;
}
.react-bootstrap-table-page-btns-ul{
    justify-content: flex-end;
    margin: 0;
}
#pageDropDown{
    background-color: #181919 !important;
    color: white !important;
    border: none;
    padding: 0.5rem 1.5rem !important;
}
.dropdown-menu{
    width: 20px !important;
}
.dropdown-item a{
    text-decoration: none;
    color: #181919;
}
.page-link{
    color: #181919 !important;
    padding: 0.5rem 1.5rem !important;
}
.active.page-item .page-link{
    background-color: #181919 !important;
    text-decoration: none ;
    border: none;
    color: white !important;
    /* font-size: 2rem !important; */
}
/* ************ filter *********** */
.sr-only{
    display: none;
}


@media screen and (max-width: 600px) {
    tbody > tr{
        padding: 1rem 12rem !important;
    }
    td{
        padding: 0.5rem !important;
        font-weight: 400;
        border: none !important;
        word-wrap:break-word;
        font-size: 0.8rem;
        /* white-space: nowrap; */
    }
    table  thead th{
        padding: 0.2rem;
        font-size: 0.8rem;
    }
}
/* *********************** agence *********************** */
.bouton {
    background-color: #00BEC9;
    ;
    color: #ffffff;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    width: 100px;
    height: 40px;
}
.bouton:hover {
    background-color: #00BEC9;
    ;
}
.agences-para {
    font-size: 14px;
    line-height: 17.07px;
    font-weight: 400;
}
.agences-card .card-text {
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
    color: #78858F;
}
.agences-card {
    width: 224.33px;
    height: 334px;
    box-shadow: 0.623134px 1.8694px 2.49254px rgba(0, 0, 0, 0.25);
    border-radius: 12.4627px;
}
.agences-card .card-img-top {
    width: 100%;
    height: 159.37px;
}
.agence-banner{
    height: 30vh;
    background-color: rgb(106, 101, 101);
    width: 100%;
}

/* *********************Start Agence************************* */
.bouton {
    background-color: #00BEC9;
    ;
    color: #ffffff;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    width: 100px;
    height: 40px;
}

.bouton:hover {
    background-color: #00BEC9;
    ;
}

.agencesContainer {
    text-align: left !important;
}
.agences-title {
    font-size: 30px;
    line-height: 37px;
    font-weight: 500;
}

.agences-card .card-title {
    font-size: 17.4478px;
    line-height: 21px;
    font-weight: 500;
}
.icon-resize{
    font-size: calc(0.8rem + 1.2vw);
}
.btn-resize{
    padding: calc(0.4rem + 0.2vw) calc(0.5rem + 0.8vw);
}


.agences-card {
    width: 224.33px;
    height: 334px;
    box-shadow: 0.623134px 1.8694px 2.49254px rgba(0, 0, 0, 0.25);
    border-radius: 12.4627px;
}
.agences-card .card-img-top {
    width: 100%;
    height: 159.37px;
}
.agence-logo--container{
    position: absolute;
    bottom: -25px;
    left: 20px;
    width: 150px;
    height: 150px;
    /* padding: 1rem; */
    background-color: white;
    /* border-radius: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.agence-logo--container img{
    width: 100px;
    height: 100px;
    /* border-radius: 100%; */
}
.agence_gerant--description *{
    color: white;
}
.agence_gerant--description p{
    margin: 0;
}
/* !----------------padding left------------ */
.agence_gerant--description{padding-left: 190px;}
.agence-tab li{
    padding: 0 2rem;
}
.agence-tab a{
    color: black;
    padding: .1rem .5rem;
    /* padding-bottom: ; */
}
.agence-tab a:hover{
    color: var(--secondary-color);
}
.agence-tab a.active{
    color: var(--secondary-color);
    border-bottom: 5px solid var(--secondary-color);
}
.agence-tab ul{
    padding-left: 110px;
    overflow-x: auto;
}