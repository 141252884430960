.gerantsContainer {
    text-align: left !important;
}
.bouton {
    background-color: #00BEC9;
    ;
    color: #ffffff;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    width: 100px;
    height: 40px;
}
.bouton:hover {
    background-color: #00BEC9;
    ;
}